export const createLappu = (lappu) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        firestore.collection('laput').add({
            ...lappu,
            user_id: getState().firebase.auth.uid
        }).then(() => {
            dispatch({type: 'CREATE_LAPPU', lappu})
        }).catch(error => {
            dispatch({type: 'CREATE_LAPPU_ERROR', error})
        });
    }
};