import authReducer from './authReducer';
import lappuReducer from './lappuReducer';
import { combineReducers} from "redux";
import { firestoreReducer} from "redux-firestore";
import { firebaseReducer} from "react-redux-firebase";

const rootReducer = combineReducers({
   auth: authReducer,
   lappu: lappuReducer,
   firestore: firestoreReducer,
   firebase: firebaseReducer
});

export default rootReducer;