import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import {createLappu} from '../../store/actions/lappuActions';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";


class Create extends React.Component {
    state = {
        open: false,
        trip: "",
        date: "",
        statement: "",
        overnight: false,
        place: "",
        allowance: false,
        hours: 0

    };

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleSave = () => {
        this.handleClose();
        this.props.createLappu({...this.state, "date": Number(moment(this.state.date).valueOf())})
    };

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };

    render() {

        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Uusi lappu</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="trip"
                            label="Työmatka alku/loppu"
                            type="text"
                            fullWidth
                            value={this.state.trip}
                            onChange={this.handleChange("trip")}
                        />
                        <TextField
                            id="date"
                            label="Päiväys"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            value={this.state.date}
                            onChange={this.handleChange("date")}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="statement"
                            label="Selvitys työstä"
                            type="text"
                            fullWidth
                            value={this.state.statement}
                            onChange={this.handleChange("statement")}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.overnight}
                                    onChange={this.handleChange('overnight')}
                                    color="primary"
                                />
                            }
                            label="Yöpyminen"
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="place"
                            label="Paikkakunta ja ajokilometrit"
                            type="text"
                            fullWidth
                            value={this.state.place}
                            onChange={this.handleChange("place")}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.allowance}
                                    onChange={this.handleChange('allowance')}
                                    color="primary"
                                />
                            }
                            label="Päiväraha"
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="hours"
                            label="Tunnit"
                            type="number"
                            fullWidth
                            value={this.state.hours}
                            onChange={this.handleChange("hours")}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Sulje
                        </Button>
                        <Button onClick={this.handleSave} color="primary">
                            Tallenna
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createLappu: (lappu) => dispatch(createLappu(lappu))
    }
};
export default connect(null, mapDispatchToProps)(Create);