import React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {connect} from "react-redux";
import {signIn} from "../../store/actions/authActions";
import {Redirect} from 'react-router-dom';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
});

class SignIn extends React.Component {

    state = {
        email: "",
        password: ""
    };

    handleChange = name => event => {
        this.setState({[name]: event.target.value});
    };
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state);
        this.props.history.push('/');
    };

    render() {
        if (this.props.auth.uid) return <Redirect to="/"/>;
        return (
            <Grid container spacing={16}>
                <Grid item xs={4}>
                    <Card elevation={1}>
                        <CardContent>
                            {this.props.authError ? <p>{this.props.authError}</p> : null}
                            <form noValidate autoComplete="off">
                                <Grid item xs={12}>
                                    <TextField
                                        id="email"
                                        label="Sähköpostiosoite"
                                        margin="normal"
                                        variant="filled"
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        value={this.state.email}
                                        onChange={this.handleChange('email')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="password"
                                        label="Salasana"
                                        margin="normal"
                                        variant="filled"
                                        type="password"
                                        autoComplete="current-password"
                                        value={this.state.password}
                                        onChange={this.handleChange('password')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" size="large" color="primary"
                                            onClick={this.handleSubmit}>
                                        Kirjaudu sisään
                                    </Button>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn));