const initState = {
    authError: null
};
const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOGIN_ERROR':
            console.log("Error");
            return {
                ...state,
                authError: action.error.message
            };
        case 'LOGIN_SUCCESS':
            console.log("Success");
            return {
                ...state,
                authError: null
            };
        case 'SIGNOUT_SUCCESS':
            console.log("Success");
            return state;
        case 'SIGNUP_SUCCESS':
            console.log("Success");
            return {
                ...state,
                authError: null,
            };
        case 'SIGNUP_ERROR':
            console.log("Error");
            return {
                ...state,
                authError: action.error.message
            };
        default:
            return state;
    }
};

export default authReducer;