const initState = {
    laput: []
};
const lappuReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_LAPPU':
            return state;
        case'CREATE_LAPPU_ERROR':
            console.log("Error while creating new lappu", action.err);
            return state;
        default:
            return state;
    }
};

export default lappuReducer;