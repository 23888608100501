import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyDVGs6YSZ-Mg-wMYgL4Ulw2duSc2W8Ets8",
    authDomain: "tuntilappu-40265.firebaseapp.com",
    databaseURL: "https://tuntilappu-40265.firebaseio.com",
    projectId: "tuntilappu-40265",
    storageBucket: "tuntilappu-40265.appspot.com",
    messagingSenderId: "569779458934"
};
firebase.initializeApp(config);

export default firebase;
