import React from 'react';
import List from "../laput/List";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import {compose} from 'redux';
import {Redirect} from 'react-router-dom';

class Dashboad extends React.Component {
    render() {
        if (!this.props.auth.uid) return <Redirect to='/signin'/>;
        return (
            <div>
                <List laput={this.props.laput}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        laput: state.firestore.ordered.laput,
        auth: state.firebase.auth
    }
};

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        {
            collection: 'laput',
            where: [
                ['user_id', "==", props.auth.uid]
            ]
        }
    ])
)(Dashboad);
