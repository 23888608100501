import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Home, Menu, People, Settings, Add, ExitToApp} from '@material-ui/icons';
import Toolbar from '@material-ui/core/Toolbar';
import {withStyles} from '@material-ui/core/styles';
import Dashboard from "./components/dashboard/Dashboard";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import SignIn from "./components/auth/SignIn";
import Create from "./components/laput/Create";
import {connect} from "react-redux";
import {signOut} from './store/actions/authActions';
import SignUp from "./components/auth/SignUp";

const drawerWidth = 240;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
});

class App extends React.Component {
    state = {
        mobileOpen: false,
    };

    handleDrawerToggle = () => {
        this.setState(state => ({mobileOpen: !state.mobileOpen}));
    };

    onClick = () => {
        this.child.handleClickOpen()
    }

    render() {
        const {classes, theme} = this.props;

        const drawer = (
            <div>
                <div className={classes.toolbar}/>
                <Divider/>
                <List>
                    <ListItem button>
                        <ListItemIcon><Home/></ListItemIcon>
                        <ListItemText primary="Etusivu"/>
                    </ListItem>
                    <Create onRef={ref => (this.child = ref)}/>
                    <ListItem button onClick={this.onClick}>
                        <ListItemIcon><Add/></ListItemIcon>
                        <ListItemText primary="Uusi lappu"/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon><People/></ListItemIcon>
                        <ListItemText primary="Työntekijät"/>
                    </ListItem>
                    <ListItem button>
                        <ListItemIcon><Settings/></ListItemIcon>
                        <ListItemText primary="Asetukset"/>
                    </ListItem>
                    <ListItem button onClick={this.props.signOut}>
                        <ListItemIcon><ExitToApp/></ListItemIcon>
                        <ListItemText primary="Kirjaudu ulos"/>
                    </ListItem>
                </List>
            </div>
        );

        return (
            <BrowserRouter>
                { this.props.auth.uid ?
                <div className={classes.root}>
                    <CssBaseline/>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <Menu/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <nav className={classes.drawer}>
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                        <Hidden smUp implementation="css">
                            <Drawer
                                container={this.props.container}
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={this.state.mobileOpen}
                                onClose={this.handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant="permanent"
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                    </nav>
                    <main className={classes.content}>
                        <div className={classes.toolbar}/>
                        <Switch>
                            <Route exact path="/" component={Dashboard}/>
                            <Route exact path="/dashboard" component={Dashboard}/>
                        </Switch>
                    </main>
                </div>
                    :
                    <Switch>
                        <Route exact path="/" component={SignIn}/>
                        <Route exact path="/signin" component={SignIn}/>
                        <Route exact path="/signup" component={SignUp}/>
                    </Switch>
                }
            </BrowserRouter>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, {withTheme: true})(App));